<template>
	<div class="shetyy-login-wrap">
		<div id="mask">
			<img :src="imgSrc" />
		</div>
	</div>
</template>

<script>
import { setStorage } from "@/utils/storage"
import { getHospitalLogo } from "@/api/user/user"
import publicApi from "@/api/public"

const { publicGetEnv, publicGetSysSetting } = publicApi
export default {
	name: "loginIndex",
	data() {
		return {
			imgSrc: `${process.env.VUE_APP_PUBLIC_BASE_URL}resource/images/common/loading.gif`
		}
	},
	created() {
		// this.getEnv();
		this.getHospitalLogo()
		// 获取所有枚举
		this.$store.dispatch("getEnumerate")
	},
	mounted() {},
	methods: {
		async getEnv() {
			// 获取当前登录环境
			this.isOk = false
			const res = await publicGetEnv()
			if (res) {
				this.$store.commit("updateState", { environment: res.data })
				sessionStorage.setItem("environment", res.data)
			}
			if (this.$route.name === "login") {
				const res2 = await publicGetSysSetting("40")
				if (res2) {
					const list =
						res2.data && res2.data.data ? JSON.parse(res2.data.data) : []
					const itemC = list.find(item => item.type === "onoff")
					this.isShowDownLoad = itemC && itemC.value
					if (this.isShowDownLoad) {
						const itemF = list.find(item => item.type === "file")
						this.fileId = itemF ? itemF.fileId : ""
					}
				}
			}
			this.isOk = true
		},
		// 获取中心的logo
		getHospitalLogo() {
			this.$asyncDo(async () => {
				const res = await getHospitalLogo()
				if (res) {
					this.hospitalLogo = res.data
					setStorage({ hospitalLogo: res.data })
				}
			})
		}
	}
}
</script>
<style lang="less">
.shetyy-login-wrap {
	#mask {
		position: absolute;
		z-index: 9999;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		//background-position: 50%;
		//background-repeat: no-repeat;
		background-color: #ffdfb9;

		img {
			transform: scale(0.5);
		}

		p {
			text-align: center;
			font-size: 26px;
			margin-bottom: -190px;
		}
	}
}
</style>
